.instagram {
    &-wrapper {
        position: relative;
    }

    // &-slider {
    //     position: relative;
    //     z-index: 1;
    //     // .swiper-container, .swiper-wrapper, .swiper-slide {
    //     //     z-index: 1;
    //     // }
    // }

    &-slide {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .button {
        &-wrapper {
            z-index: 2;
            @include absoluteCenter;
        }
        
        &-content {
            z-index: 3;
        }
        &-inner {
            &::after {
                z-index: 2;
            }
        }
    }
}