.hero {
    .container {
        position: relative;
        .row {
            align-items: center;
        }
    }

    &-wrapper {
        position: relative;
    }

    margin-bottom: 5rem;

    @media (max-width: 991px) {
        margin-bottom: 6rem;
    }

    &-image {
        width: 100%;
        height: 100%;

        padding: 17rem 0;

        display: flex;
        justify-content: flex-end;

        position: relative;
        
        > img {
            position: relative;
            z-index: 2;
        }

        @media (max-width: 991px) {
            justify-content: center;
            padding: 17.8rem 0 5rem 0;
        }

        @media (max-width: 575px) {
            position: static;
            padding: 5rem 0 0 0;
            > img {
                transform: scale(.7) 
            }

            .hero-absolute-image {
                img {
                    transform-origin: top center;
                    transform: scale(.7)
                }
            }
        }
    }

    &-content {
        position: relative;
        z-index: 2;

        padding: 17rem 0;

        p {
            margin: 3rem 0 4.8rem 0;
        }

        @media (max-width: 991px) {
            padding: 0 0 5rem 0;
            text-align: center;

            p {
                margin: 3rem 0 3rem 0; 
            }
        }

        @media (max-width: 575px) {
            padding: 0 0 5rem 0;
            margin-top: -5rem;
        }
    }


    .background-pattern {
        width: 100%;
        border-radius: 0;
        
        &-img, &-gradient {
            border-radius: 0 !important;
        }
    }
}